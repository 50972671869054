* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  width: 100vw;
  max-width: 100%;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
